/** @jsx jsx */
import { jsx } from 'theme-ui'
import logo from '../images/site/Scala-Center-horizontal.png'

import React from 'react'

import Header from '../sections/Header/Header'
import Footer from '../sections/Footer/Footer'
import {
  Helmet,
  Container,
  H3
} from 'gatsby-theme-landmarks-core'

const Gdpr = props => {
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Scala Frunzisului | Ansamblu rezidential in Cluj-Napoca"
        />
        <meta
          name="description"
          content="Politica de Confidenţialitate şi Protecţia Datelor cu Caracter Personal"
        />
        <title>Ansamblu rezidential in orasul Cluj-Napoca</title>
      </Helmet>
      <Header logoSrc={logo} logoAlt="Scala Frunzisului" />

      <Container
        sx={{
          pt: '200px',
          pl: 4,
          pr: 4,
          pb: 4
        }}
      >
        <div
          sx={{
            px: [0, 0, 7]
          }}
        >
          <H3>
            Politica de Utilizare Cookie-uri
          </H3>
          <p>
          Site-ul nostru web utilizează cookie-uri pentru a furniza Dvs., vizitatorilor, o experienţă mai buna de navigare şi servicii adaptate nevoilor şi interesului fiecăruia.
Vă rugăm să acordaţi atenţie aspectelor prezentate în cele ce urmează, respectiv la mesajul ce apare la accesarea site-lui nostru prin intermediul căreia puteţi decide dacă sunteţi sau nu de acord ca site-ul nostru să salveze fişiere de tip cookie ăn browserul Dvs.
          </p>
  <br></br>
  <H3>
  1. CE ESTE UN COOKIE?
 </H3>
<p>
Cookie-ul este un fişier de mici dimensiuni, format din litere şi cifre, care va fi stocat pe computerul, terminalul mobil sau orice alt echipament de pe care utilizatorul accesează internetul. Cookie-ul este instalat prin solicitarea emisă de către un web-server unui browser (ex: Internet Explorer, Chrome, Edge, etc.) şi este complet "pasiv". Max exact, nu conţine programe software, viruşi sau spyware şi nu poate accesa informaţiile de pe hard-ul (spaţiul de stocare) al echipamentului utilizat de Dvs. pentru accesarea site-ului web.
</p>
<br></br>
<H3> 2. LA CE SUNT FOLOSITE COOKIE-URILE?
</H3>
<p>
Aceste fişiere fac posibilă recunoaşterea terminalului utilizatorului şi prezentarea conţinutului într-un mod relevant, adaptat preferinţelor acestuia. Cookie-urile au rolul să vă asigure o experienţă plăcută de navigare, utilizare a paginii noastre web şi susţin eforturile noastre de a vă oferi servicii comfortabile: de exemplu stochează preferinţele Dvs. in materie de confidenţialitate online, coşul de cumpărături (în cazul site-urilor de tip magazin online) sau vă oferă publicitate relevantă. De asemenea, sunt utilizate în pregătirea unor statistici anonime agregate care ne ajută să înţelegem modul în care utilizatorul beneficiează de paginile noastre web, permitându-ne îmbunătăţirea permanentă a structurii şi continutului lor, excluzând identificarea personală a utilizatorului sau colectarea oricăror informaţii de natură personală despre aceasta.
</p>
<br></br>
<H3>
  3. CARE ESTE DURATA DE VIAŢĂ A UNUI COOKIE?
</H3>
<p>
Durata de viaţă a unui cookie poate fi diferită, in funcţie de scopul pentru care este plasat. Există cookie-uri folosite exclusiv pentru o singură sesiune, acestea ne mai fiind reţinute după ce utilizatorul părăseşte site-ul web, şi există aşa numite cookie-uri permanente, care sunt reţinute (salvate) şi refolosite de fiecare dată când utilizatorul revine pe acel website, însă pot fi şterse oricând, manual, de utilizator.
</p>
<br></br>
<H3> 4. CE COOKIE-URI FOLOSIM?
</H3>
<p>
Folosim două tipuri de Cookie-uri: de sesiune şi persistente. Cele de sesiune sunt fişiere temporare care rămân în terminalul utilizatorului până la terminarea sesiunii de navigare sau închiderea aplicaţiei (browser-ului web). Cele fixe însă, rămân stocate pe terminalul (hard drive-ul) utilizatorului pe o perioadă definită de parametrii Cookie-ului sau până ce sunt şterse manual de utilizator.
</p>
<br></br>
<H3>5. CUM ŞI ÎN CE SCOPURI SUNT FOLOSITE COOKIE-URILE DE CĂTRE SITE-UL NOSTRU?
</H3>
<p>
O vizita pe site-ul nostru poate plasa următoarele tipuri de cookie-uri:

Cookie-uri de performanţă a site-ului: acest tip de cookie reţine preferinţele utilizatorului pe acest site, astfel încât nu mai este nevoie de setarea lor la fiecare vizitare a site-ului. Spre exemplu: setările volumului pentru video player, viteza de video streaming cu care este compatibil browserul.
Cookie-uri de analiză a vizitatorilor: de fiecare dată când un utilizator vizitează acest site, softul de analiză (Google analytics) furnizat de o terţă parte (în cazul nostru Google Inc.) generează un cookie de analiză a utilizatorului. Acest cookie ne spune dacă aţi mai vizitat acest site până acum. Browserul ne va spune dacă aveţi acest cookie, iar daca nu, vom genera unul. Acesta permite monitorizarea utilizatorilor unici care ne vizitează şi cât de des o fac. Acest cookie nu poate fi folosit pentru a identifica persoanele fizice, ele sunt folosite doar în scop statistic. Dacă sunteţi înregistrat pe site-ul nostru (unde există posibilitatea creării unui cont) putem şti, de asemenea, detaliile pe care ni le-aţi furnizat, cum ar fi adresa de e-mail, nume şi prenume, acestea fiind supuse confidenţialităţii şi prevederilor din Termeni si Conditii, Politica de confidenţialitate precum şi prevederilor legislaţiei în vigoare cu privire la protejarea datelor cu caracter personal.
Cookie-uri de înregistrare: atunci când vă înregistraţi pe site-ul nostru (unde există posibilitatea creării unui cont), generăm un cookie care ne anunţă dacă sunteţi înregistrat sau nu. Serverele noastre folosesc aceste cookie-uri pentru a ne arăta contul cu care sunteţi îngregistrat şi dacă aveţi permisiunea pentru un serviciu anume. De asemenea, ne permite să asociem orice comentariu pe care îl postaţi pe site-ul nostru cu username-ul dvs.
Cookie-uri pentru publicitate: Aceste cookie-uri ne permit să aflăm dacă aţi vizualizat sau nu o reclamă online, care este tipul acesteia şi ce durată de timp a trecut de când aţi văzut mesajul publicitar. Aceste cookie-uri se folosesc şi pentru a targeta publicitatea online. Putem folosi, de asemenea, cookie-uri aparţinând unei terţe părţi, pentu o mai bună targetare a publicităţii, pentru a arăta de exemplu reclame despre anumite produse, dacă utilizatorul a vizitat recent un articol pe site despre acele produse. Aceste cookie-uri sunt anonime, ele stochează informaţii despre conţinutul vizualizat, nu despre utilizatori. De asemenea, setăm cookie-uri anonime şi prin alte site-uri pe care avem publicitate. Primindu-le, astfel, noi le putem folosi pentru a vă recunoaşte ca vizitator al acelui site dacă ulterior veţi vizita site-ul nostru, vă vom putea livra publicitatea bazată pe aceasta informaţie.
Cookie-uri ale furnizorilor de publicitate: există posibilitatea să găsiţi pe site-ul nostru publicitate aparţinând terţelor părţi. Unele dintre aceste părţi folosesc propriile cookie-uri anonime pentru a analiza cât de multe persoane au fost expuse unui mesaj publicitar, sau pentru a vedea câte persoane au fost expuse de mai multe ori la aceeaşi reclamă. Companiile care generează aceste cookie-uri au propriile politici de confidenţialitate, iar acest site nu are acces pentru a citi sau scrie aceste cookie-uri. Cookie-urile acestor terţe părţi pot fi folosite pentru a vă arăta publicitate targetată şi pe alte site-uri, bazându-se pe navigarea dvs. pe acest site.
          </p>
          <br></br>
<H3>6. CONŢIN COOKIE-URILE DATE PERSONALE?
</H3>
<p>
Cookie-urile în sine nu solicită informaţii cu caracter personal pentru a putea fi utilizate şi în cele mai multe cazuri, nu identifică personal utilizatorii de internet. Eventualele date personale colectate prin utilizarea Cookie-urilor pot avea unicul scop de a facilita anumite funcţionalităţi pentru utilizator. Aceste date sunt criptate intr-un mod care face imposibil accesul persoanelor neautorizate la ele.
</p>
<br></br>
<H3>
7. ŞTERGEREA COOKIE-URILOR
</H3>
<p>
În general, aplicaţiile folosite pentru accesarea paginilor web permit salvarea Cookie-urilor pe terminal în mod implicit. Controlul însă vă aparţine:

La prima accesare site-ul vă oferă opţiunea să consultaţi politica noastră de cookie-uri şi să decideţi dacă acceptaţi sau nu salvarea cookie-urilor. Prin accesarea opţiunii “Accept” îi acordaţi site-lui nostru posibilitatea să salveze cookie-uri în browserul, respectiv pe echipamentul Dvs. de pe care accesaţi site-ul. În caz de “Refuz” site-ul nostru nu va salva cookie-uri în browserul, respectiv pe hard drive-ul echipamentului Dvs.
Setările implicite ale browserului pot fi schimbate, de asemenea, în aşa fel încât administrarea automata a Cookie-urilor să fie blocată sau să fiţi informat de fiecare dată când Cookie-uri sunt trimise către terminalul Dvs. Informaţii detaliate despre posibilităţile şi modurile de administrare a Cookie-urilor pot fi găsite în zona de setări ale aplicaţiei (browser-ului web). Limitarea folosirii Cookie-urilor poate afecta anumite funcţionalităţi ale paginii web.
</p>
<br></br>
<H3>
  8. DE CE SUNT COOKIE-URILE IMPORTANTE PENTRU INTERNET?
</H3>
<p>Cookie-urile reprezinta punctul central al funcţionării eficiente a Internetului, ajutând la generarea unei experienţe de navigare prietenoase şi adaptata preferinţelor şi intereselor fiecărui utilizator. Refuzarea sau dezactivarea cookieurilor poate face unele site-uri imposibile de folosit.

          Refuzarea sau dezactivarea cookie-urilor nu înseamnă că nu veţi mai primi publicitate online,ci doar că aceasta nu va mai putea ţine cont de preferinţele şi interesele dvs., evidenţiate prin comportamentul de navigare.

          Exemple de întrebuinţări importante ale cookieurilor (care nu necesită autentificarea unui utilizator prin intermediul unui cont):

          Conţinut şi servicii adaptate preferinţelor utilizatorului– categorii de produse si servicii;
          Oferte adaptate pe interesele utilizatorului – reţinerea parolelor
          Reţinerea filtrelor de protecţie a copiilor privind conţinutul pe Internet (opţiuni family mode, funcţii de safe search)
          Limitarea frecvenţei de difuzare a reclamelor – limitarea numărului de afişări a unei reclame pentru un anumit utilizator pe un site
          Furnizarea de publicitate mai relevanta pentru utilizator- măsurarea, optimizarea şi caracteristicile de analytics, cum ar fi confirmarea unui anumit nivel de trafic pe un website, ce tip de conţinut este vizualizat şi modul cum un utilizator ajunge pe un website (ex. prin motoare de căutare, direct, din alte website-uri etc). Website-urile derulează aceste analize a utilizării lor pentru a îmbunătăţi site-urile în beneficiul utilizatorilor
 </p>
        </div>
        <br />
      </Container>

      <Footer />
    </>
  )
}

export default Gdpr
